import { LoginPage } from '@/components/pages/login'
import { Meta } from '@/layouts'
import { MainLayout } from '@/layouts/main-layout'
import { TNextPageWithLayout } from '@/types/layout'
import useContentGlobal from '@/hooks/useContentGlobal'

const Login: TNextPageWithLayout = () => {
  const { getValueByKey } = useContentGlobal();

  return (
    <>
      <Meta title={getValueByKey("Sign_In") ?? 'Đăng nhập'} description={getValueByKey("Sign_In") ?? 'Đăng nhập'} canonical={process.env.NEXT_PUBLIC_DEPLOY_URL + 'login'} />
      <LoginPage numberTab={0} />
    </>

  )
}


Login.Layout = MainLayout

export default Login
